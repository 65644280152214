/*

CONTENTS:
1. Global
2. Login
3. Snackbars (for Updates)
4. Drawers
5. User Registration
6. Provider Registration
7. Requests
8. Groups
9. Farmer Crops
10. Reports
11. Wallet
12. Stock
13. Other

*/

/* Global
   ========================================================================== */

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.height-0 {
  line-height: 2.25;
}

.width-100 {
  width: 100% !important;
}

@media (max-width: 599.98px) {
  .center:not(.stick) {
    text-align: unset !important;
  } 
}

.MuiAppBar-root .app-title+.MuiTypography-root {
  font-weight: 600;
}

.MuiPaper-root .MuiAppBar-root .MuiTab-textColorPrimary.Mui-selected {
  color: #3f51b5 !important;
}

.MuiPaper-root .MuiAppBar-root .MuiTabs-indicator,
.MuiButton-containedPrimary.colour,
.MuiPaper-root .MuiTabs-indicator {
  background-color: #3f51b5 !important;
}

.list-page .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root {
  background-color: rgb(250, 250, 250) !important;
}

[class*='MuiRadio'][class*='Mui-checked'] {
  color: #3f51b5 !important;
}

[class*='MuiCheckbox'][class*='Mui-checked'] {
  color: #3f51b5 !important;
}

.list-page>.MuiToolbar-root .MuiButton-textPrimary:hover, .list-page>.MuiToolbar-root .MuiButton-textPrimary:focus {
  color: #3f51b5 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.list-page.members {
  margin-top: 4.5em;
}

.list-page.credit .MuiPaper-root > .MuiToolbar-root {
  color: #3f51b5 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.MuiPaper-root .MuiTable-root:not(.requested-items) {
  table-layout: fixed;
}

.MuiPaper-root .MuiTable-root>a, .MuiPaper-root .MuiTable-root>a.MuiButton-root {
  color: #3f51b5 !important;
}

.MuiPaper-root .MuiTable-root>a.MuiButton-root:hover, .MuiPaper-root .MuiTable-root>a.MuiButton-root:focus {
  color: #3f51b5 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.MuiPaper-root .MuiTable-root .MuiButton-textPrimary, .MuiPaper-root .MuiTable-root .MuiIconButton-root {
  color: #3f51b5 !important;
}

.MuiPaper-root .MuiTable-root .MuiButton-textPrimary:hover, .MuiPaper-root .MuiTable-root .MuiButton-textPrimary:focus {
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.MuiPaper-root .MuiCardContent-root > .ra-input {
  display: block !important;
}

main [class*='page'] .MuiPaper-root:first-of-type {
  padding: 0 4px;
}

.two-thirds-column {
  width: 66%;
}

.full-column {
  width: 100%;
}

.full-column #service_providers {
  display: flex;
}

.sm-block {
  display: block !important;
}

.main-dashboard-cards { 
  margin-top: 35px;
  margin-bottom: 30px; 
}

.main-dashboard-cards .sm-wide {
  height: 20px !important;
  width: 20px !important;
}

.MuiCardHeader-content {
  flex-grow: 0 !important;
}

.modal-field > .MuiFormLabel-root,
.add-to-program > .MuiFormLabel-root {
  color: #3f51b5 !important;
}

.MuiPaper-root .simple-form.switch>.MuiToolbar-root .MuiButton-containedPrimary {
  background-color: #3f51b5 !important;
}

.add-to-program > .MuiFilledInput-underline:after {
  border-color: #3f51b5 !important;
}

.AkAction--popup .MuiMenu-list>.MuiMenuItem-root:not(:first-child) {
  border-top: 1px solid #F2F2F2;
}

/* Hide the "checkboxes" within the list (mobile) */

.MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:first-child, .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:first-child {
  display: none;
}

/* Unhide for sending "sms" */

.MuiPaper-root .bulk-sms>.MuiTable-root.receiver>.MuiTableHead-root>.MuiTableRow-root>th:first-child, .MuiPaper-root .bulk-sms>.MuiTable-root.receiver>.MuiTableBody-root>.MuiTableRow-root>td:first-child {
  display: table-cell;
}

/* Unhide for credit-requests */

.MuiPaper-root .reg-requests>.MuiTable-root.pending>.MuiTableHead-root>.MuiTableRow-root>th:first-child, .MuiPaper-root .reg-requests>.MuiTable-root.pending>.MuiTableBody-root>.MuiTableRow-root>td:first-child {
  display: table-cell;
}

.MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child,
.MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:last-child {
  text-align: center;
}

.reg-providers>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
  width: 22.5%;
}

/* truncate "program(s)" column title */
.reg-providers>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(6) {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row-height>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root {
  height: 43px;
}

.MuiPaper-root .row-height>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child,
.MuiPaper-root .row-height>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:last-child {
  text-align: left;
}


@media (min-width: 600px) {
  .reg-groups>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 11%;
  }  
  .reg-groups>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5) {
    width: 7%;
  }  
  .reg-groups>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(7) {
    width: 6%;
  } 
  .reg-groups>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(11) {
    width: 8%;
  }
  /* sacco members */
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 12%; /* member's name */
  }  
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 4%;  /* gender */
  } 
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(4) {
    width: 5%;  /* age */
  } 
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(8) {
    width: 6%; /* reg. date */
  }  
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(9) {
    width: 12%; /* reg. by */
  }  
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(10) {
    width: 3%; /* active */
  }  
  .reg-members.no-edit>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(10) {
    width: 4%; /* active */
  } 

  /* credit requests */
  .reg-requests>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 12%; /* requested-on */
  }  
  .reg-requests>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5) {
    width: 10%; /* member-name */
  }
  /* credit requests - approved/rejected section */
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 7%; /* request no. */
  }  
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 9%; /* requested on. */
  } 
  .reg-requests>.MuiTable-root.handled>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    letter-spacing: 0.5px; /* requested on. */
  }
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(4) {
    width: 12%; /* approved on. */
  }  
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5) {
    width: 8%; /* approved by. */
  } 
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(9) {
    width: 6%; /* village */
  }  
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(n+10):nth-child(-n+11),
  .reg-requests>.MuiTable-root.handled>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(n+10):nth-child(-n+11) {
    width: 5%; /* parish -> district */    
  }  
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(12) {
    width: 7%; /* action */    
  }
  .reg-requests.cash>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+2):nth-child(-n+9),
  /* requested on -> member */
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(n+3):nth-child(-n+7),
  .reg-requests>.MuiTable-root.handled>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(n+3):nth-child(-n+7),
  /* village -> district */
  .reg-requests>.MuiTable-root.handled>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(n+9):nth-child(-n+11),
  .reg-requests>.MuiTable-root.handled>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-sizeSmall:nth-child(n+9):nth-child(-n+11) {
    padding-right: 0px;
  }
  .reg-requests>.MuiTable-root.pending>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 7%; /* request-no. */
  }
  .reg-requests>.MuiTable-root.pending>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 15%; /* request-no. */
  }
  .reg-requests>.MuiTable-root.pending>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(9) {
    width: 4%; /* district */
  } 
  .reg-requests>.MuiTable-root.pending>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(10) {
    width: 8%; /* manage */
  }

  /* aggregated requests */
  .reg-requests>.MuiTable-root.aggregated>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 15%; /* aggregated on. */
  }
  .reg-requests>.MuiTable-root.aggregated>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5) {
    width: 11%; /* aggregated on. */
  }

  /* aggregated requests - dialog-list (request bulk list) */
  .reg-requests>.MuiTable-root.req-details>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 9.85%; /* requested no. */
  } 
  .reg-requests>.MuiTable-root.req-details>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 14.5%; /* requested on. */
  } 
  .reg-requests>.MuiTable-root.req-details>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5) {
    width: 7.5%; /* member */
  } 
  .reg-requests>.MuiTable-root.req-details>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:last-child {
    width: 11%; /* manage */
  } 

  /* cash requests */
  .reg-requests.cash>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 14%; /* request-on. */
  }
  .reg-requests.cash>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(4) {
    width: 8%; /* amount */
  }
  .reg-requests.cash>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5) {
    width: 15%; /* farmer-on. */
  }

  /* programs */
  .reg-programs>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 10%;
  }
  .reg-programs>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(6),
  .reg-programs>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(7) {
    width: 12%;
  }
  .reg-programs>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(8),
  .reg-programs>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(9) {
    width: 5%;
  }

  /* funders */
  .reg-funders>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(6) {
    width: 8%;
  }

  /* loans */   
  .reg-loanproducts>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 11%; /* Created-on */
  }  
  .reg-loanproducts>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(4) {
    width: 11%; /* Payment period */
  }   
  .reg-loanproducts>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root,
  .reg-loanproducts>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root,  
  .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root,
  .reg-loanapps>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root,
  .reg-loanpays>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root,
  .reg-loanpays>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root {
    vertical-align: top;
  } 
  .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2),
  .reg-loanpays>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 3%; /* appl. no. */
  }  
  .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 12%; /* created on. */
  }   
  .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+4):nth-child(-n+9) {
    width: 6.5%; /* needed by. */
  } 
 .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(13),
 .reg-loanapps>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(13) {
    padding-left: 0px;
 }
 .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+6):nth-child(-n+9),
 .reg-loanapps>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+6):nth-child(-n+9),
 .reg-loanpays>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+6):nth-child(-n+12),
 .reg-loanpays>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+6):nth-child(-n+12) {
    padding-left: 4px;
  } 
  .reg-loanapps>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+2):nth-child(-n+12),
  .reg-loanapps>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+2):nth-child(-n+12),
  .reg-loanpays>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+2):nth-child(-n+12),
  .reg-loanpays>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+2):nth-child(-n+12) {
    padding-right: 0px; /* appl. no -> contact */
  } 

  .reg-loanpays>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
    width: 10%;
  }
  .reg-loanpays>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(n+4):nth-child(-n+10) {
    width: 7%;
  }  
  .report-sms>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 12.5%;
  }
  .report-sms>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(6) {
    width: 12%;
  }
}

@media (max-width: 599.98px) {
  /* list page (tested on user-list) */
  .layout header>.MuiToolbar-root {
    padding-right: 18px;
  }
  .list-page .MuiPaper-root:first-of-type {
    margin-top: -45px;
  }
  .list-page>.MuiToolbar-root>.MuiToolbar-root>.MuiIconButton-root {
    display: none !important;
  }
  .list-page>.MuiToolbar-root,
  .list-page>.MuiToolbar-root>.MuiToolbar-root {
    background-color: transparent;
  }
  .list-page .MuiTabs-root .MuiButtonBase-root>.MuiTab-wrapper {
    align-items: flex-start;
  }
  .reg-providers>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2),
  .reg-farmers>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2),
  .reg-agents>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2),
  .reg-others>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2),
  .reg-members>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2),
  .reg-groups>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 45%;
  }
  .reg-programs>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 26.5%;
  }

}

.report-sms>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(6) > span {
  text-decoration: underline;
  color: #3f51b5;  
  cursor: pointer;
}


/* Login
   ========================================================================== */


.akb .MuiPaper-root.MuiCard-root:not(.report-card)>div:first-of-type [class*='MuiAvatar'] {
  background-color: #3f51b5 !important;
}


/* Snackbar (for Updates)
   ========================================================================== */


.UpdatesBar [class*='MuiSnackbarContent'] [class*='message'] .MuiButton-textPrimary:hover, .UpdatesBar [class*='MuiSnackbarContent'] [class*='message'] .MuiButton-textPrimary:focus, .UpdatesBar [class*='MuiSnackbarContent'] [class*='message'] .MuiButton-textPrimary:active {
  color: #fff !important;
}

.UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] {
  margin-right: 0px !important;
  padding-left: 6px !important;
}

.UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton'] {
  color: #ccc;
}

.UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton']:hover, .UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton']:focus, .UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton']:active {
  color: #fff !important;
}


/* Drawers
   ========================================================================== */

@media (max-width: 599.98px) {

  .MuiDrawer-paperAnchorLeft {
    min-height: 100%;
  }
  [class*=MuiDrawer] [class*=MuiMenuItem] {
    font-size: 1.125rem;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }
}


/* User Registration
   ========================================================================== */


.user-registration .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child, .register-form .MuiFormControl-root[class*='create']>div:first-of-type>div:first-child {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.user-registration .add-to-district .MuiFormControl-root:not(.branch) .MuiInputBase-root,
.user-registration .add-to-group .MuiFormControl-root:not(.branch) .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.user-registration .add-to-district .MuiFormControl-root.add-to-program .MuiInputBase-root,
.user-registration .add-to-group .MuiFormControl-root.add-to-program .MuiInputBase-root {
  padding-top: 0px;
  padding-left: 0px;
}

.user-registration .MuiFormLabel-root {
  color: #3f51b5 !important;
}

.user-registration .add-to-district .MuiInput-underline:before,
.user-registration .add-to-group .MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.user-registration .add-to-district .MuiInput-underline:hover:not(.Mui-disabled):before,
.user-registration .add-to-group .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.user-registration .add-to-district .MuiInput-underline:after,
.user-registration .add-to-group .MuiInput-underline:after {
  border-color: #3f51b5 !important;
}

.user-registration .add-to-district .MuiFormControl-root:not(.add-to-program) label.MuiInputLabel-formControl,
.user-registration .add-to-group .MuiFormControl-root label.MuiInputLabel-formControl {
  margin-left: 12px;
  margin-top: -6px;
}

.user-registration .add-to-district .MuiFormControl-root label.MuiInputLabel-formControl {
  margin-top: 0px;
}

.user-registration .add-to-district .MuiFormControl-root label+.MuiInput-formControl,
.user-registration .add-to-group .MuiFormControl-root label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.user-registration .add-to-district .MuiFormControl-root label.MuiInputLabel-formControl.MuiInputLabel-shrink,
.user-registration .add-to-group .MuiFormControl-root label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.user-registration .add-to-district .select-district,
.user-registration .add-to-group .select-group {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  width: 280px;
  max-width: 300px;
  max-height: 50vh !important;
  overflow-y: auto;
}

.user-registration .add-to-district .select-district {
  margin-top: 86.5px;
}

.user-registration .add-to-group .select-group {
  margin-top: 60px;
}

@media (min-width: 960px) { 
  .basic-form > .MuiCardContent-root:first-child, 
  .register-form > .MuiCardContent-root:first-child {
    padding-top: 26px !important;
  } 
  .farmer-info .basic-form.farmer > .MuiCardContent-root:first-child {
    padding-top: 2px !important;
  }
  .register-form .MuiFormControl-root.new-photo {
    margin:  0px !important;
  }

  .simple-form.seasonal-form .MuiFormControl-root {
    margin: 8px 0 4px 0 !important;    
  }
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div {
    width: 206px !important;
  }
  .basic-form .aside-section .MuiFormControl-root[class*='input']>div, .basic-form .aside-section .MuiFormControl-root>div {
    width: 250px !important;
  }
  .basic-form .MuiFormControl-root.rich-text>div, .register-form .MuiFormControl-root.rich-text[class*='input']>div, .register-form .MuiFormControl-root.rich-text>div {
    width: 655px !important;
  } 
  .register-form fieldset.MuiFormControl-root > div {
    width: 320px !important;
  }
  .basic-form [class*='MuiFormGroup'],
  .register-form [class*='MuiFormGroup'] {
    display: inline-flex !important;
    flex-direction: row !important;
    margin: 0.625em 1em 1.25em 0 !important;
  }
  .user-registration .add-to-district .MuiTypography-root {
    margin-top: 0.85em;
  }
  .user-registration .add-to-district .MuiFormControl-root {
    margin-top: 2.35em !important;
  }
}

@media (max-width: 959.99px) {
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin: 0.5em 1.375em 0.9375em 0 !important;
  }
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div {
    width: 250px !important;
  }
}

.simple-form .MuiToolbar-root {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 8px;
}

.simple-form .MuiToolbar-root.one-button {
  justify-content: end !important;
}

.simple-form.register-form > .MuiToolbar-root > .save-and-exit-button {
  visibility: hidden;
}

.simple-form .AkRegisterForm-body {
  width: auto;
}

.simple-form .AkRegisterForm-title {
  margin-right: 8.5px;
}

.simple-form .AkRegisterForm-title.mt-ve {
  margin-top: -38.65px;
}

.AkRegisterForm-body .capitalize .MuiInputBase-root > input {
  text-transform: capitalize !important;
}

.simple-form .AkRegisterForm-role {
  display: inline-flex;
  margin-right: 10px;
}

@media (min-width: 960px) {
  .simple-form {
    min-height: 540px;
  }
  .simple-form.seasonal-form {
    min-height: 0px;
  }
  .simple-form .MuiToolbar-root {
    margin-top: 72.5px;
  }
  .simple-form.single .MuiToolbar-root,
  .simple-form.register-form .MuiToolbar-root {
    margin-top: 0;
  }  
  .simple-form.register-form .MuiToolbar-root.selected-address {
    margin-top: 170px;
  }
  .simple-form.loan-product .MuiToolbar-root,
  .simple-form.seasonal-data .MuiToolbar-root {
    margin-top: 0px;
  }
  .simple-form.seasonal-form .MuiToolbar-root {
    margin-top: 0px;
    background-color: transparent;
  }
  .MuiToolbar-gutters.one-button {
    padding-right: 42px;
  }
}


.MuiPaper-root .simple-form>.MuiToolbar-root .MuiButton-containedPrimary:first-child, .MuiPaper-root .simple-form>.MuiToolbar-root .MuiButton-containedPrimary:hover, .MuiPaper-root .simple-form>.MuiToolbar-root .MuiButton-containedPrimary:focus, .MuiPaper-root .simple-form>.MuiToolbar-root .MuiButton-containedPrimary:active {
  background-color: #3f51b5 !important;
}

.MuiPaper-root .basic-form>.MuiToolbar-root .MuiButton-containedPrimary, .MuiPaper-root .basic-form>.MuiToolbar-root .MuiButton-containedPrimary:hover, .MuiPaper-root .basic-form>.MuiToolbar-root .MuiButton-containedPrimary:focus, .MuiPaper-root .basic-form>.MuiToolbar-root .MuiButton-containedPrimary:active {
  background-color: #3f51b5 !important;
}

.MuiPaper-root .register-form>.MuiToolbar-root .MuiButton-containedPrimary, .MuiPaper-root .register-form>.MuiToolbar-root .MuiButton-containedPrimary:hover, .MuiPaper-root .register-form>.MuiToolbar-root .MuiButton-containedPrimary:focus, .MuiPaper-root .register-form>.MuiToolbar-root .MuiButton-containedPrimary:active {
  background-color: #3f51b5 !important;
}

.MuiPaper-root .individual-pro-form>.MuiToolbar-root .MuiButton-containedPrimary, .MuiPaper-root .individual-pro-form>.MuiToolbar-root .MuiButton-containedPrimary:hover, .MuiPaper-root .individual-pro-form>.MuiToolbar-root .MuiButton-containedPrimary:focus, .MuiPaper-root .individual-pro-form>.MuiToolbar-root .MuiButton-containedPrimary:active {
  background-color: #3f51b5 !important;
}

.MuiPaper-root .company-pro-form>.MuiToolbar-root .MuiButton-containedPrimary, .MuiPaper-root .company-pro-form>.MuiToolbar-root .MuiButton-containedPrimary:hover, .MuiPaper-root .company-pro-form>.MuiToolbar-root .MuiButton-containedPrimary:focus, .MuiPaper-root .company-pro-form>.MuiToolbar-root .MuiButton-containedPrimary:active {
  background-color: #3f51b5 !important;
}

.MuiPaper-root .MuiToolbar-root [class='MuiCircularProgress'] {
  color: #eff0f1 !important;
}

@media (min-width: 600px) {
  .simple-form .MuiFormControl-root.multiple-select-large > label.MuiFormLabel-filled {
    display: none;
  }

  .simple-form .MuiFormControl-root.multiple-select-large > label.MuiFormLabel-filled+div .MuiFilledInput-inputMarginDense {
    padding-top: 6.45px;
  }
}

@media (max-width: 599.98px) {
  .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:last-child .MuiSvgIcon-root {
    font-size: 1.85rem;
  }
  .MuiPaper-root .MuiTable-root .MuiTableCell-root {
    padding-left: 6px;
    padding-right: 3px;
  }
  .MuiPaper-root .MuiTable-root .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root:last-child {
    text-align: center;
  }

  /* registration form */
  main {
    width: 100%;
  }
  main [class*='page'] .MuiPaper-root:first-of-type {
    margin: 0 10px;
  }

  main [class*='page'] .MuiPaper-root.MuiExpansionPanel-root:first-of-type {
    margin: 10px 0 0;
  }

  main [class*='page'] .MuiPaper-root.MuiExpansionPanel-root:first-of-type {
    padding: 0px;
  }

  /* avoid overriding list style (alignment) on mobile */
  main .list-page .MuiPaper-root:first-of-type,  
  main .list-page .MuiPaper-root.farmer-request--card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 2 100%;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 8px;
    border-top-left-radius: 21.5px;
    border-top-right-radius: 21.5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 21.5px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
  [class*=MuiTypography].AkRegisterForm-title {
    margin-top: 0.35em;
    margin-bottom: 0px;
  }
  .simple-form .AkRegisterForm-body {
    width: 100% !important;
    text-align: center;
  }
  .simple-form .AkRegisterForm-body .MuiFormGroup-root {
    justify-content: center;
  }
  .simple-form .check {
    width: 102px !important;
  }
  .simple-form .create {
    margin-left: -3.5px;
  }
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin-right: 0 !important;
    text-align: left;
  }
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div, .simple-form.provider .address.details.edit ul section>div:not(:nth-child(4)) {
    width: 292px !important;
  }
  .simple-form.provider .address.details.edit ul section>div:not(:nth-child(4)) {
    display: inline-block;
  }
  .simple-form .MuiToolbar-root .MuiButtonBase-root {
    font-size: 0.85em;
  }
  .simple-form > .MuiToolbar-root .MuiSvgIcon-root {
    display: none;
  }
  .simple-form.provider .MuiToolbar-root {
    padding: 6px 28.75px;
  }
  .simple-form.provider .centered {
    text-align: center;
  }
  .simple-form.provider .MuiToolbar-root .direction .MuiSvgIcon-root {
    display: inline-block;
    font-size: 2rem;
  }
  .simple-form .AkRegisterForm-role {
    margin-left: -15px;
  }
  .simple-form hr {
    color: rgba(0, 0, 0, 0.12) !important;
  }
  .simple-form .MuiFormLabel-root {
    font-size: 1.25rem;
  }  
  .simple-form .rich-text .MuiFormLabel-root {
    font-size: 1.05rem;
  }
  .simple-form .MuiFormControl-root.add-to-program .MuiFormLabel-root {
    font-size: 1.05rem;
  }
}

@media (max-width: 320px) {
  .simple-form.provider.register-form > .MuiToolbar-root {
    padding: 16px !important;
  }
  .simple-form .MuiToolbar-root > .MuiButton-root,
  .simple-form.provider.register-form > .MuiToolbar-root > .MuiButton-root {
    padding: 6px 10px !important;
    font-size: 0.75em !important;
  }
}

/* Provider Registration
   ========================================================================== */


.individual-pro-form > .MuiCardContent-root:first-child {
  padding-top: 0 !important;
}

.individual-pro-form .MuiCardContent-root .ra-input:nth-child(2),
.individual-pro-form .MuiCardContent-root .ra-input:nth-child(4) {
  margin-top: 12.5px !important;
}

.individual-pro-form .MuiCardContent-root .ra-input > [class*='MuiBox'] {
  width: 100% !important;
}

.individual-pro-form .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  padding: 16.5px 8px !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.individual-pro-form .MuiFormControl-root[class*='edit'][class*='registered']>div:first-of-type>div:first-child, .individual-pro-form .MuiFormControl-root[class*='edit'][class*='registered']>div:first-of-type>div:first-child {
  margin-top: 21px !important;
}

.individual-pro-form legend.MuiFormLabel-root {
  font-size: 1.375em !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

@media (min-width: 960px) {
  .individual-pro-form .MuiFormControl-root[class*='input'], .individual-pro-form .MuiFormControl-root {
    margin: 0.5em 1.875em 1.125em 0 !important;
  }
  .individual-pro-form .MuiFormControl-root[class*='input']>div, .individual-pro-form .MuiFormControl-root>div {
    width: 250px !important;
  }
}

.individual-pro-form fieldset.MuiFormControl-root {
  margin-bottom: 20px !important;
}

.company-pro-form > .MuiCardContent-root:first-child {
  padding-top: 0 !important;
}

.company-pro-form .MuiCardContent-root .ra-input:not(:first-child) {
  margin-top: 12.5px !important;
}

.company-pro-form .MuiCardContent-root .ra-input:last-child {
  margin-top: 30px !important;
}

.company-pro-form .MuiCardContent-root .ra-input > [class*='MuiBox'] {
  width: 100% !important;
}

.company-pro-form .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  padding: 16.5px 8px !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.company-pro-form .MuiFormControl-root[class*='edit'][class*='registered']>div:first-of-type>div:first-child, .company-pro-form .MuiFormControl-root[class*='edit'][class*='credentials']>div:first-of-type>div:first-child {
  margin-top: 58px !important;
}

.company-pro-form [class*='MuiExpansionPanel'] .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  margin-top: -12px !important;
}

.company-pro-form .MuiCardContent-root .ra-input:nth-child(5) [class*='MuiExpansionPanelDetails'] {
  flex-flow: row wrap !important;
}

.company-pro-form [class*='MuiExpansionPanel'] .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  margin-top: -12px !important;
}

.company-pro-form .MuiFormControl-root[class*='edit'][class*='identity']>div:first-of-type>div:first-child, .company-pro-form .MuiFormControl-root[class*='edit'][class*='identity']>div:first-of-type>div:first-child {
  margin-top: 18px !important;
}

.company-pro-form legend.MuiFormLabel-root {
  font-size: 1.375em !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

@media (min-width: 960px) {
  .company-pro-form .MuiFormControl-root[class*='input'], .company-pro-form .MuiFormControl-root[class*='MuiTextField'] {
    margin: 0.5em 1.875em 1.125em 0 !important;
  }
  .company-pro-form .MuiFormControl-root[class*='input']>div, .company-pro-form .MuiFormControl-root[class*='MuiTextField']>div {
    width: 250px !important;
  }
}

.company-pro-form fieldset.MuiFormControl-root {
  margin-bottom: 20px !important;
}

.simple-form.provider [class*='dropZone'] {
  min-height: 32px !important;
}

.simple-form.provider .upload [class*='dropZone'] {
  padding: 40px 8px;
  margin-bottom: 45px;
}

.simple-form [class*='MuiStepper'] {
  padding-top: 10px !important;
  padding-bottom: 48px !important;
}

.simple-form [class*='MuiStepper'] [class*='MuiStepLabel'][class*='MuiTypography'] {
  font-size: 22px !important;
}

.simple-form:not(.main-filter) > .MuiCardContent-root:first-child {
  padding-bottom: 26px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

@media (max-width: 599.98px) {
  .simple-form:not(.main-filter) > .MuiCardContent-root:first-child {
    padding: 5% !important;
  } 
}

.simple-form.bulk > .MuiCardContent-root:first-child {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (max-width: 320px) {
  .simple-form > .MuiCardContent-root:first-child {
    padding-left: 0px !important; 
    padding-right: 0px !important;
    margin: 0 auto !important;
  }
}

.simple-form .certification section>.ra-input:not(:last-child), .simple-form .address.details.edit section>.ra-input:not(:nth-child(3)) {
  display: inline-block;
}

.simple-form .address.details.edit section>.ra-input:nth-child(4), .simple-form .address.details.edit section>.ra-input:nth-child(4)>div, .simple-form .address.details.edit section>.ra-input:nth-child(5) {
  width: 100%;
}

.simple-form .address.details.edit section .MuiFormControl-root {
  vertical-align: baseline;
  margin-bottom: 32px !important;
}

@media (min-width: 960px) {
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin: 0.5em 0.5em 1.125em 0 !important;
  }
  .simple-form.provider .address.details>[class*='MuiBox']>div:last-of-type .MuiFormControl-root {
    margin-right: 4.375em !important;
  }
  .simple-form.provider .refugee-field {
    margin-bottom: 29px;
  }
  .simple-form.provider [class*='MuiFormGroup'] {
    flex-wrap: nowrap;
  }
}

@media (min-width: 1260px) {
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin: 0.5em 1.125em 1.125em 0 !important;
  }
}

.simple-form.provider .certification .MuiFormControl-root[class*='input']>div {
  width: 100% !important;
}

.simple-form.provider .certification>.MuiFormControl-root>ul>li {
  margin-right: 20px;
}

.main-filter .MuiInputBase-root,
.simple-form.register-form .address .MuiFormControl-root:not(.branch) .MuiInputBase-root,
.simple-form.provider .address .MuiFormControl-root:not(.branch) .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.main-filter .ra-input .MuiInputBase-root {
  padding-top: 23.25px;
  padding-bottom: 7px;
}

.main-filter {
  margin-bottom: 2.5px;
}

.main-filter label {
  margin-left: 14px;
}

.main-filter .ra-input label {
  margin-top: 6px;
  margin-left: 4px;
}

.main-filter label + .MuiInputBase-root {
  margin-top: 2.5px;
}

.main-filter .MuiIconButton-root {
  margin-top: -15px;
  color: rgba(0, 0, 0, 0.35);
}

.main-filter label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: 6.5px;
}

.main-filter .ra-input label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-top: 1.35px;
  margin-left: 0px !important;
}

.main-filter .MuiFormLabel-root,
.main-filter .MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.main-filter .select-location {
  position: absolute;
  left: 260px;
  right: 0;
  z-index: 2;
  margin-top: 1px;
  width: 245px;
  max-width: 300px;
  max-height: 50vh !important;
  overflow-y: auto;
}

.with-closed-sidebar .main-filter .select-location {
  left: 75px;
  width: 290px;
}

.with-closed-sidebar .simple-form.main-filter .ra-input .MuiFormControl-root {
  width: 100%;
}

.with-closed-sidebar .simple-form.date-filter .ra-input:first-child .MuiFormControl-root {
  width: 94%;
}

.with-closed-sidebar .simple-form.date-filter .ra-input:last-child .MuiFormControl-root {
  width: 92%;
} 

.simple-form.main-filter {
  min-height: 0px;
  margin-left: 6px;
}

.simple-form.main-filter .ra-input p {
  display: none;
}

.simple-form.main-filter .ra-input{
  max-height: 16px;
}

.simple-form.main-filter > .MuiCardContent-root:first-child,
.simple-form.main-filter .MuiFormControl-root .MuiSelect-root {
  padding: 0px !important;
}

.simple-form.main-filter .MuiFormControl-root .MuiSelect-root {
  background-color: transparent;
}

.simple-form.main-filter .MuiFormControl-root {
  margin-top: 0px !important;
  -webkit-transition: linear 195ms;
  transition: linear 195ms;
}

.main-filter .MuiInput-underline:not(.Mui-disabled):before,
.main-filter .MuiFilledInput-underline:not(.Mui-disabled):before,
.date-filter .MuiFilledInput-underline:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0,0,0,.095);
}

.date-filter .MuiInput-underline:hover:not(.Mui-disabled):before,
.main-filter .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0,0,0,.095);
}

.date-filter .MuiInputBase-root.Mui-focused:after, 
.main-filter .MuiInputBase-root.Mui-focused:after, 
.date-filter .MuiFilledInput-underline:after,
.main-filter .MuiFilledInput-underline:after,
.main-filter .MuiInput-underline:after {
  border-color: #3f51b5 !important;
}

.main-filter .MuiInputBase-root:not(.Mui-focused),
.date-filter .MuiInputBase-root:not(.Mui-focused) {
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-color: rgba(0,0,0,.095);
  background-color: transparent;
}

.main-filter .MuiInputBase-root:hover,
.main-filter .MuiInputBase-root:focus,
.date-filter .MuiInputBase-root:hover,
.date-filter .MuiInputBase-root:focus,
.main-filter .MuiInputBase-root.MuiInputBase-adornedEnd {
  background-color: rgba(0, 0, 0, 0.045);
}

.simple-form.date-filter .ra-input p,
.simple-form.main-filter .ra-input p {
  display: none;
}

.simple-form.date-filter {
  min-height: 0px;
  width: 52.5%;
}

.date-filter .MuiCardContent-root {
  width: 100%;
}

.simple-form.date-filter > .MuiCardContent-root:first-child {
  padding: 0px !important;
}

.date-filter .MuiCardContent-root > .ra-input {
  display: inline-flex !important;
  flex-direction: column;
  margin-top: 2px;
  width: 50%;
}

.date-filter .MuiCardContent-root > .ra-input:last-child .MuiFormControl-root {
  margin-left: 25px;
  width: 252px;
}

.simple-form.date-filter .MuiFormControl-root {
  margin-top: 0px !important;
  -webkit-transition: linear 195ms;
  transition: linear 195ms;
}

.date-filter .ra-input .MuiFilledInput-inputMarginDense {
  padding-top: 24.5px;
}

.date-filter .MuiFormLabel-root,
.date-filter .MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.simple-form.register-form .address .MuiFormControl-root:not(.branch) .MuiFilledInput-root,
.simple-form.provider .address .MuiFormControl-root:not(.branch) .MuiFilledInput-root {
  padding-top: 0;
}

/* Service provider form */

.simple-form.provider .address .select-address {
  display: inline-block !important;
}

.simple-form.register-form .address label+.MuiInput-formControl,
.simple-form.provider .address label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.simple-form.register-form .address .MuiFormControl-root:not(.branch) label.MuiInputLabel-formControl,
.simple-form.provider .address .MuiFormControl-root:not(.branch) label.MuiInputLabel-formControl {
  margin-left: 12px !important;
  margin-top: -6px;
}

.simple-form.register-form .address .MuiFormControl-root:not(.branch) label.MuiInputLabel-filled,
.simple-form.provider .address .MuiFormControl-root:not(.branch) label.MuiInputLabel-filled {
  margin-left: 0px !important;
  margin-top: 0px;  
}

.simple-form.register-form .address .MuiFormControl-root label.MuiInputLabel-formControl.MuiInputLabel-shrink,
.simple-form.provider .address .MuiFormControl-root label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
}

.simple-form.register-form .address .MuiInputLabel-shrink,
.simple-form.provider .address .MuiInputLabel-shrink {
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.simple-form.register-form .address label+.MuiInputBase-input,
.simple-form.provider .address label+.MuiInputBase-input {
  padding: 23px 12px 10px !important;
}

.simple-form.register-form .address .MuiFilledInput-root:hover,
.simple-form.provider .address .MuiFilledInput-root:hover {
  background-color: rgba(0, 0, 0, 0.13);
}

.simple-form.register-form .address .MuiInput-underline:before,
.simple-form.provider .address .MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.simple-form.register-form .address .select-address .MuiInput-underline:after,
.simple-form.provider .address .select-address .MuiInput-underline:after {
  border-bottom: 2px solid #3f51b5 !important;
}

.simple-form.provider.first>.MuiToolbar-root>button:first-of-type {
  visibility: hidden;
}

.simple-form .unique-field, .simple-form .hidden {
  display: none !important;
}

.simple-form.provider > .MuiToolbar-root > .MuiButton-root,
.simple-form.provider > .MuiToolbar-root .btn-group > .MuiButton-root {
  padding: 8px 11.5px !important;
  font-size: 0.875em !important;
}

.simple-form.provider>.MuiToolbar-root .btn-group>[class*='MuiButton']:last-child {
  margin-left: 15px;
}

.simple-form.provider .download [class*='MuiButton'] {
  padding: 3.5px 9.5px !important;
  font-size: 1em !important;
}

.simple-form.provider .download .MuiButton-root:hover, .simple-form.provider .download .MuiButton-root:focus, .simple-form.provider .download .MuiButton-root:active {
  color: #3f51b5 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.simple-form.provider .download .MuiButton-root {
  margin-left: 3.85em;
}

.simple-form.provider .address:first-of-type .MuiFormControl-root {
  margin-bottom: 12px !important;
}

.simple-form.provider .address:nth-of-type(3n) .MuiFormControl-root:first-of-type {
  display: none !important;
}

.simple-form.provider .address .MuiFormLabel-root, .simple-form.provider .address .MuiFormLabel-root.Mui-focused, .simple-form.basic-form .MuiFormLabel-root, .simple-form.basic-form .MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.simple-form.provider .address:nth-of-type(3n) .MuiFormLabel-root, .simple-form.provider .address:nth-of-type(3n) .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.7) !important;
}

.simple-form.register-form .address .MuiInput-root.Mui-focused:after,
.simple-form.provider .address .MuiInput-root.Mui-focused:after, 
.simple-form.basic-form .MuiInput-root.Mui-focused:after {
  border-color: #3f51b5 !important;
}

.simple-form.register-form .address .select-location,
.simple-form.provider .address .select-location {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  margin-top: 60px;
  width: 280px;
  max-width: 300px;
  max-height: 50vh !important;
  overflow-y: auto;
}

@media (max-width: 599.98px) {

  /* Provider registration form */
  .simple-form.provider .address, .simple-form.provider .select-services {
    text-align: center;
  }
  .simple-form.provider .address .select-location {
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
  }
  .simple-form.provider .address .select-location .MuiListItem-root {
    padding-left: 6px;
    padding-right: 6px;
  }

  .simple-form.provider > .MuiCardContent-root:first-child {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .simple-form.provider .stepper-lg-header {
    display: none;
  }
  .simple-form.provider .stepper-sm-header {
    display: block !important;
    font-size: 1.5em;
    padding-top: 9px;
    padding-bottom: 20px;
  }
  .simple-form .MuiStepper-horizontal {
    flex-direction: column;
  }
  .simple-form .MuiStepper-horizontal .MuiStep-horizontal:not(:last-child) {
    padding-bottom: 8px;
  }
  main [class*='page'] .MuiPaper-root[class*='MuiStepper']:first-of-type {
    margin-left: 0px;
    margin-right: 0px;
  }
  .simple-form [class*='MuiStepper'] [class*='MuiStepLabel'][class*='MuiTypography'] {
    font-size: 19.25px !important;
  }
  .stretch {
    margin-bottom: 250px !important;
  }
}

/* Handling the provider (stepper) form */

.simple-form.provider .steps {
  margin: 1em 0 0 1em;
}

.simple-form.provider .personel {
  margin-left: 48px;
}

@media (max-width: 599.98px) {
  .simple-form.provider .personel {
    margin-left: 0px;
  }  
}

.simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
  width: 269px;
}

@media (max-width: 599.98px) {
  /* Provider registration form */
  .simple-form.provider .address, .simple-form.provider .select-services {
    text-align: center;
  }
  .simple-form.provider .address .select-location {
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
  }
  .simple-form.provider .address .select-location .MuiListItem-root {
    padding-left: 6px;
    padding-right: 6px;
  }

  .simple-form.provider > .MuiCardContent-root:first-child {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .simple-form.provider .stepper-lg-header {
    display: none;
  }
  .simple-form.provider .stepper-sm-header {
    display: block !important;
    font-size: 1.5em;
    padding-top: 9px;
    padding-bottom: 20px;
  }
  .simple-form .MuiStepper-horizontal {
    flex-direction: column;
  }
  .simple-form .MuiStepper-horizontal .MuiStep-horizontal:not(:last-child) {
    padding-bottom: 8px;
  }
  main [class*='page'] .MuiPaper-root[class*='MuiStepper']:first-of-type {
    margin-left: 0px;
    margin-right: 0px;
  }
  .simple-form [class*='MuiStepper'] [class*='MuiStepLabel'][class*='MuiTypography'] {
    font-size: 19.25px !important;
  }
  .stretch {
    margin-bottom: 250px !important;
  }
}

/* Handling the provider (stepper) form */

.simple-form.provider .steps {
  margin: 1em 0 0 1em;
}

.simple-form.provider .personel {
  margin-left: 48px;
}

@media (max-width: 599.98px) {
  .simple-form.provider .personel {
    margin-left: 0px;
  }  
}

.simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
  width: 269px;
}

@media (min-width: 960.98px) {
  .simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
    width: 224px;
  }
}

@media (max-width: 718.98px) {
  .simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
    width: 292px;
  }
  .simple-form.provider .address.details ul section>div:first-child > .MuiFormControl-root,
  .simple-form.provider .address.details ul section>div:nth-child(2) > .MuiFormControl-root {
    margin-bottom: 12px !important;
  }
}

@media (max-width: 599.98px) {
  .simple-form.provider .address.details ul section>div:first-child > .MuiFormControl-root,
  .simple-form.provider .address.details ul section>div:nth-child(2) > .MuiFormControl-root {
    margin-right: 36px !important;
  }
}

@media (max-width: 425.98px) {
  .simple-form.provider .address.details ul section>div:first-child > .MuiFormControl-root,
  .simple-form.provider .address.details ul section>div:nth-child(2) > .MuiFormControl-root {
    margin-left: 2.25px !important;
  }
}

/* hide "remove" button for the default (first) branch location */

.simple-form.provider .address.details ul>li:first-child > span {
  display: none;
}

/* hide the "add" button from provider edit-mode */

.simple-form.provider .address.details ul>li:not(:first-child) {
  display: none;
}

/* hide the small header, if not mobile */

.simple-form.provider .stepper-sm-header {
  display: none;
}

.simple-form.provider .address.details ul>li {
  border-bottom: none;
}

.simple-form.provider .MuiFormControl-root.address.details {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* handle provider services registration */

.simple-form.services .MuiFilledInput-underline:after {
  border-bottom: 2px solid #3f51b5 !important;
}

.simple-form.services .MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.programs-dialog .MuiPaper-root {
  min-width: 350px;
}

/* support view increase; when searching village */

.stretch {
  margin-bottom: 180px !important;

}/* support view increase; when searching existing user */

.stretch--district,
.stretch--user {
  margin-bottom: 40px !important;
}

.form--subsection {
  border-bottom: 1px solid rgba(0,0,0,0.045);
  margin-bottom: 30px !important;
}

/* support stretching dashboard to fill mobile view-port*/

.agent--view {
  flex-direction: row !important;
}

/* provider services list/card */

.simple-form.provider .select-services .MuiButton-root {
  margin: 0 37.25px;
}

.simple-form.provider .select-services .MuiButton-root:first-of-type {
  margin: 15px 9.25px 42.5px;
}

.simple-form.add-service .MuiFormControl-root .MuiFilledInput-underline:after,
.simple-form.provider .select-services .MuiFormControl-root .MuiFilledInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form.provider .services-list {
  padding-right: 20px;
}

.simple-form.provider .services-list .services-card {
  width: 100%;
  min-height: 70px;
  padding: 0px;
  margin: 0.85em 0.5em 0.85em 0;
  display: flex;
}

.simple-form.provider .services-list .services-card.crops {
  min-height: 45px;
}

.simple-form.provider .services-title {
  align-items: flex-start;
  background: #fafafa;
  width: 260px;
  padding: 4px 16px;
}

.simple-form.provider .services-title>.MuiCardHeader-root span.MuiTypography-body2 {
  font-size: 1rem;
}

.simple-form.provider .services-title>.MuiCardHeader-root>span.MuiTypography-root .services-measure {
  font-size: 0.75rem !important;
}

.simple-form.provider .services-card .services-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.simple-form.provider .services-card .services-details>div>span:first-of-type {
  padding-left: 13.25px;
  font-size: 0.895rem;
}

.simple-form.provider .services-card .services-details .MuiChip-root {
  display: block;
  height: 26px;
  margin-right: 16.25px;
  margin-top: 4.5px;
  vertical-align: baseline;
}

.simple-form.provider .services-card .services-details .MuiChip-root .MuiChip-deleteIcon {
  height: 20px;
  width: 40px;
}

.simple-form.provider .provider-services .MuiToolbar-root {
  margin-top: -32px;
}

.simple-form.provider .provider-services .MuiTablePagination-root .MuiToolbar-root {
  margin-top: 0px;
}

.simple-form.provider .provider-services .MuiTablePagination-root .MuiToolbar-root .MuiButton-root {
  min-width: 48px;
}

.simple-form.provider .provider-services .MuiToolbar-root>.MuiButton-root {
  padding-left: 8px;
  padding-right: 8px;
}

.simple-form.provider .provider-services .MuiToolbar-root>.MuiButton-root:first-child {
  margin-right: 12px;
}

.simple-form .MuiFormControl-root.multiple-select-large > div {
  width: 431.15px !important;
}

.simple-form .MuiFormControl-root.multiple-select-large:not(.disabled) > .MuiFormLabel-root,
.simple-form .MuiFormControl-root.multiple-select-small:not(.disabled) > .MuiFormLabel-root {
  color: #3f51b5;
}

.simple-form .MuiFormControl-root.multiple-select-large .MuiFilledInput-underline:after,
.simple-form .MuiFormControl-root.multiple-select-small .MuiFilledInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form .MuiFormControl-root.multiple-select-large .MuiChip-root,
.simple-form .MuiFormControl-root.multiple-select-small .MuiChip-root {
  border: 2px solid #3f51b5;
}

.simple-form.provider .MuiFormControl-root.select-disabled .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.simple-form.provider .MuiFormControl-root.select-disabled label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.simple-form.provider .MuiFormControl-root.select-disabled label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.simple-form.provider .title {
  margin: 0 auto 16.5px;
}

/* add service modal */


.simple-form.add-service .MuiFormControl-root {
  width: 256px;
}

@media (min-width: 960px) {
  .simple-form.add-service {
    min-height: 350px;
  }
}

@media (min-width: 600px) {
  /* Services stepper */
  .simple-form.provider .stepper-content {
    width: 100% !important;
  }
}

@media (max-width: 599.98px) {
  /* Services stepper */
  .simple-form.provider .stepper-content.services {
    width: 100% !important;
  }
  .simple-form.provider .address:first-of-type .MuiFormControl-root {
    margin-bottom: 32px !important;
  }
  .simple-form.provider .provider-services > div > .MuiToolbar-root {
    display: none !important;
  }
  .simple-form.provider .stepper-content>.MuiBox-root:nth-child(2)>.provider-services {
    text-align: center;
  }
  .simple-form .provider-services .MuiToolbar-root .MuiSvgIcon-root {
    display: block;
  }
  .simple-form.provider .services-title {
    min-width: 32%;
    padding-left: 8px;
    padding-right: 8px;
  }
  .simple-form.provider .services-card .services-details>div>span:first-of-type {
    padding-left: 0px;
  }
  .simple-form.provider .services-card .services-details .MuiChip-root {
    margin-bottom: 6px;
  }
  .simple-form.provider .services-card .services-details .MuiChip-root .MuiChip-deleteIcon {
    height: 20px;
    width: 18px;
    margin-bottom: -3.5px;
  }
  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 96.65%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  .simple-form.provider .form--subsection > div > .MuiToolbar-root {
    display: none !important;
  }

  .simple-form.provider .listed-services .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 0;
  }

  .simple-form.provider .services-list {
    padding-right: 0;
  }

  .simple-form.provider .services-title .MuiCardHeader-content > span:nth-child(2) {
    text-align: left;
  }

  .simple-form.provider .AkRegisterForm-body .add-service .MuiButton-root {
    display: block;
    margin: 0 auto !important;
  }
}

@media (max-width: 425.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 373px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 95%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-toolbar {
    float: left;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 120px;
  }
}

@media (max-width: 375.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 323px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 94%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 86px;
  }

  .simple-form.provider .services-title {
    min-width: 25%;
    text-align: left;
  }
}

@media (max-width: 320.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 268px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 93.25%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 35px;
  }

  .simple-form.only-services .MuiFormControl-root>div {
    width: 216px !important;
  }

  .simple-form.add-service .MuiFormControl-root {
    width: 216px;
  }
}


/* Requests
   ========================================================================== */

.requested-by-title .first {
  margin-top: 8px;
}

.simple-form .MuiFormControl-root.multiple-select-large:not(.disabled) > .MuiFormLabel-root,
.simple-form .MuiFormControl-root.multiple-select-small:not(.disabled) > .MuiFormLabel-root {
  color: #3f51b5;
}

.simple-form .MuiFormControl-root.multiple-select-large .MuiFilledInput-underline:after,
.simple-form .MuiFormControl-root.multiple-select-small .MuiFilledInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form .MuiFormControl-root.multiple-select-large .MuiChip-root,
.simple-form .MuiFormControl-root.multiple-select-small .MuiChip-root {
  border: 2px solid #3f51b5;
}

.simple-form.provider .MuiFormControl-root.select-disabled label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.simple-form.provider .MuiFormControl-root.select-disabled label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.simple-form.provider .title {
  margin: 0 auto 16.5px;
}

/* add service modal */


.simple-form.add-service .MuiFormControl-root {
  width: 256px;
}

.MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3f51b5 !important;
}

.MuiPaper-root .simple-form.group.basic-form.second .MuiCardContent-root > .ra-input:nth-child(2) {
  background: #f5f5f5;
  border-radius: 6px;
}

@media (max-width: 599.98px) {

  /* farmer requests */
  main .list-page .MuiPaper-root.farmer-request--card:first-of-type {
    margin: 0 0 1rem 0;
  }
  .MuiPaper-root.farmer-request--card {
    color: #0000009f;
  }
  .farmer-request--card .MuiCardHeader-root {
    padding: 16px 16px 5px;
    border-bottom: 1px solid #0000001f;
  }
  .farmer-request--card .MuiTypography-h5, .farmer-request--card .MuiTypography-body2 {
    font-size: 1.09375rem;
  }
  .farmer-request--card .MuiCardContent-root .MuiBox-root>div:not(:first-child) {
    margin-top: 8px;
  }
  .farmer-request--card .MuiCardContent-root .MuiBox-root>div>span:first-child {
    font-weight: bold;
  }
  .action--menu .MuiMenuItem-root {
    font-weight: 600;
  }
  .MuiCard-root.requested-by-card {
    margin-bottom: 0.95em;
  }
  .small--btn.MuiIconButton-root {
    padding: 8px 10px 4px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  /* affect modal "form" "no" button */
  .MuiToolbar-root>.small--btn.MuiIconButton-root {
    padding: 6px 16px;
    line-height: 1.75;
    box-shadow: none;
  }
  .MuiToolbar-root>.small--btn.MuiIconButton-colorPrimary:hover, .MuiToolbar-root>.small--btn.MuiIconButton-colorPrimary:focus, .MuiToolbar-root>.small--btn.MuiIconButton-colorPrimary:active {
    background-color: #df1f260a;
  }
  .confirm.MuiIconButton-colorSecondary {
    background: #3f51b5;
  }
  .reject.MuiIconButton-colorSecondary {
    background: #df1f26;
  }
  .small--btn.MuiIconButton-root .MuiSvgIcon-root {
    vertical-align: middle;
    margin-top: -5px;
  }
  .requested-by-title .MuiCardHeader-content {
    max-width: 250px;
  }
}


/* Groups
   ========================================================================== */

#menu-approved .MuiMenu-paper {
  top: 55px !important;
}

.simple-form.group.basic-form.second .details {
  margin: 8px 15px;
}

.simple-form.group .MuiExpansionPanel-root .user--link {
  color: #3f51b5;
}

.simple-form.group .MuiExpansionPanel-root .user--link:hover,
.simple-form.group .MuiExpansionPanel-root .user--link:focus,
.simple-form.group .MuiExpansionPanel-root .user--link:active {
  text-decoration: underline;
}

.simple-form.group .group--managers span.user-type {
  display: inline-block;
  text-transform: capitalize;
  margin-top: 0px;
}

@media (min-width: 600px) {
  /* Group/Cooperatives stepper*/
  .simple-form.provider.group .stepper-content {
    padding: 7px;
    width: 98.75% !important;
  }
}

@media (max-width: 599.98px) {

  /* groups stepper */

  .simple-form.group .stepper-content.groups-form {
    width: 96% !important;
    margin: 0 auto;
    padding-bottom: 6.95px;
  }

  .simple-form.group.register-form .stepper-content.groups-form {
    width: 100% !important;
    margin: 0 auto;
    padding-bottom: 6.95px;
  }

  .simple-form.group > div:last-child {
    height: 8em; /* spacer */
  }

  .simple-form.group.register-form .add-role .MuiFormControl-root {
    margin: 0 auto !important;
  }

  .simple-form.group .AkRegisterForm-body .add-managers,
  .simple-form.group .AkRegisterForm-body .add-role {
    width: 96%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  .simple-form.group .AkRegisterForm-body .MuiExpansionPanelSummary-content {
    margin-left: 5.65%;
  }

  .simple-form .AkRegisterForm-role {
    display: block;
    margin-bottom: 15px;
  }

  .simple-form.group .check {
    display: block !important;
    width: 100% !important;
  }

  .simple-form fieldset .MuiFormGroup-root {
    width: 100% !important;
  }

  .simple-form fieldset legend {
    text-align: center;
    margin-bottom: 15px;
    margin-left: 5.65%;
  }

  .simple-form .address.details .MuiFormControl-root {
    margin-bottom: 32px !important;
  }

  .simple-form.group .group--managers .AkRegisterForm-body {
    text-align: left;
  }

  .simple-form.group .group--managers .AkRegisterForm-body .MuiExpansionPanelSummary-content {
    margin-left: 0px;
    align-items: center;
  }

  .simple-form.group .group--managers .AkRegisterForm-body .MuiExpansionPanelSummary-content > p {
    flex-basis: 62.5%;
  }

  .simple-form.group .group--managers .AkRegisterForm-body .MuiExpansionPanelSummary-content > p:last-child {
    font-size: 1rem;
  }

  .simple-form .multiple-select-small .MuiFormLabel-root {
    font-size: 1.05rem;
  }

  .user-registration .add-to-district .select-district,
  .user-registration .add-to-group .select-group {
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
  }
  .user-registration .add-to-district .select-district .MuiListItem-root,
  .user-registration .add-to-group .select-group .MuiListItem-root {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 425.98px) {
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div, .simple-form.provider .address.details.edit ul section>div:not(:nth-child(4)) {
    width: 250px !important;
  }
  .simple-form fieldset .MuiFormGroup-root {
    width: 100% !important;
  }
  .simple-form fieldset .MuiFormHelperText-root {
    text-align: center;
  }
}

@media (max-width: 320.98px) {
  .simple-form.group .AkRegisterForm-body .add-managers,
  .simple-form.group .AkRegisterForm-body .add-role {
    width: 87%;
  }
}


/* Farmer Crops
   ========================================================================== */

@media (max-width: 599.98px) {
  .simple-form.farmer {
    min-height: 82vh;
    margin: 0 auto;
  }
}

.simple-form.farmer .select-crops {
  display: flex;
  flex-direction: column;
  margin-top: -88px;
}

.MuiPaper-root .MuiTable-root>a.MuiButton-root:hover, .MuiPaper-root .MuiTable-root>a.MuiButton-root:focus {
  color: #3f51b5 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.simple-form.farmer .select-crops .MuiButton-root:not(.add-details) {
  margin: 15px 7px 42.5px;
}

.simple-form.farmer .select-crops .MuiButton-root:not(.add-details) {
  color: #ffffff !important;
  background-color: #3f51b5 !important;
}

.simple-form.farmer .select-crops .MuiButton-root:not(.add-details):first-of-type {
  padding: 7px 12.5px;
}

.simple-form.farmer .select-crops.sm .MuiIconButton-root,
.simple-form.farmer .farmer-brief .MuiIconButton-root {
  margin: 0 auto;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.8125rem;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.simple-form.seasonal-form .MuiButton-root:not(.add-details):first-of-type {
  color: #DF1F45 !important;
  background-color: transparent !important;
}

.simple-form.seasonal-form .MuiButton-root:not(.add-details):first-of-type:hover,
.simple-form.seasonal-form .MuiButton-root:not(.add-details):first-of-type:focus,
.simple-form.seasonal-form .MuiButton-root:not(.add-details):first-of-type:active {
  color: #DF1F45 !important;
  background-color: rgba(223, 31, 38, 0.04) !important;
  box-shadow: none;
}

.simple-form.farmer .select-crops .MuiButton-root:not(.add-details):first-of-type .MuiButton-label > span {
  padding-left: 0 !important;
}

.simple-form.farmer .select-crops .MuiButton-root.add-details {
  width: 51.25%;
  margin-left: 19.25%;
}

.farmer-crops .farmer-brief .MuiButton-root.direction .MuiButton-label > span,
.simple-form.farmer .select-crops .MuiButton-root.add-details .MuiButton-label > span {
  padding: 0 0.5em !important;
}

.simple-form.profile .MuiFormControl-root .MuiFilledInput-underline:after,
.simple-form.add-crop .MuiFormControl-root .MuiFilledInput-underline:after,
.simple-form.farmer .select-crops .MuiFormControl-root .MuiFilledInput-underline:after,
.simple-form.seasonal-form .MuiFormControl-root .MuiFilledInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form.farmer .crops-list {
  padding-right: 20px;
}

.simple-form.farmer .crops-list .crops-card {
  width: 100%;
  min-height: 70px;
  padding: 0px;
  margin: 0.85em 0.5em 0.85em 0;
  display: flex;
}

.simple-form.farmer .crops-list .crops-card.crops {
  min-height: 45px;
}

.simple-form.farmer .crops-title {
  text-align: left;
  align-items: flex-start;
  background: #fafafa;
  width: 140px;
  padding: 4px 16px;
}

.simple-form.farmer .crops-title>.MuiCardHeader-root span.MuiTypography-body2 {
  font-size: 1rem;
}

.simple-form.farmer .crops-title span.MuiTypography-body2 {
  font-size: 0.85rem;
  font-family: inherit;
}

.simple-form.farmer .crops-title.season .MuiButton-root {
  padding: 8px;
  margin-left: 10px;
  border-radius: 50%;
  min-width: 31px !important;
}

.link,
.MuiIconButton-root.direction,
.MuiButton-textPrimary.direction:not(.not-colour),
.MuiIconButton-root.add-details,
.MuiButton-textPrimary.add-details {
  color: #3f51b5 !important;
}

.crops-card .crops-details {
  line-height: 1.5;
}

@media (max-width: 599.98px) {
  .MuiIconButton-root.direction {
    padding: 6px;
    margin-left: 10px;
  }  
  .MuiIconButton-root.direction.add-details {
    padding: 12.5px;
    font-size: 1.125rem;
  }
  .crops-card .crops-details {
    line-height: 1.65;
  }
}

.MuiIconButton-root.direction:hover,
.MuiIconButton-root.direction:focus,
.MuiIconButton-root.direction:active,
.MuiButton-textPrimary.direction:hover,
.MuiButton-textPrimary.direction:focus,
.MuiButton-textPrimary.direction:active,
.MuiIconButton-root.add-details:hover,
.MuiIconButton-root.add-details:focus,
.MuiIconButton-root.add-details:active,
.MuiButton-textPrimary.add-details:hover,
.MuiButton-textPrimary.add-details:focus,
.MuiButton-textPrimary.add-details:active {
  background-color: rgba(63, 81, 181, 0.08) !important;;
}


.simple-form.farmer .crops-card .crops-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.simple-form.farmer .crops-card .crops-details>span:first-of-type,
.simple-form.farmer .crops-card .crops-details.season>span {
  font-size: 0.85rem;
  padding-left: 6.25px;
}

.simple-form.farmer .crops-card .crops-details .MuiChip-root {
  display: block;
  height: 26px;
  margin-right: 16.25px;
  margin-top: 4.5px;
  vertical-align: baseline;
}

.simple-form.farmer .crops-card .crops-details .MuiChip-root .MuiChip-deleteIcon {
  height: 20px;
  width: 40px;
}

.simple-form.farmer .farmer-crops .MuiToolbar-root {
  display: none !important;
}

.simple-form.farmer .farmer-crops .farmer-brief {
  margin-right: 30px;
  padding-bottom: 0.8em;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.simple-form.farmer .farmer-brief .title,
.simple-form.farmer .season-title .title {
  font-weight: 600;
}

.simple-form.farmer .select-crops .season-brief {
  margin: 40px 21px 0 0;
}

.simple-form.farmer .select-crops.sm .season-brief {
  margin-right: 0;
}

.simple-form.farmer .season-title {
  margin: 0px auto;
}

.simple-form.farmer .season-title .detail {
  display: block;
}

.simple-form.seasonal-form > .MuiCardContent-root:first-child {
  padding: 0 !important;
}

.simple-form.seasonal-form .ra-input .MuiFormControl-root {
  width: 224px;
}

.select-crops.sm .simple-form.seasonal-form .ra-input .MuiFormControl-root {
  width: unset;
}

.simple-form.profile .MuiFormControl-root .MuiChip-root,
.simple-form.add-crop .MuiFormControl-root .MuiChip-root {
  border: 2px solid #3f51b5;
}

.simple-form.farmer .farmer-crops .MuiTablePagination-root .MuiToolbar-root {
  margin-top: 0px;
}

.simple-form.farmer .farmer-crops .MuiTablePagination-root .MuiToolbar-root .MuiButton-root {
  min-width: 48px;
}

.simple-form.farmer .farmer-crops .MuiToolbar-root>.MuiButton-root {
  padding-left: 8px;
  padding-right: 8px;
}

.simple-form.farmer .farmer-crops .MuiToolbar-root>.MuiButton-root:first-child {
  margin-right: 12px;
}

.simple-form.farmer .MuiFormControl-root.select-disabled .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.simple-form.farmer .MuiFormControl-root.select-disabled label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.simple-form.farmer .MuiFormControl-root.select-disabled label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.simple-form.farmer .title {
  margin: 0 auto 16.5px;
}

/* Change profile picture modal */ 


.simple-form.change-profile--image .MuiFormControl-root {
  width: 256px;
}

@media (min-width: 960px) { 
  .simple-form.change-profile--image {
    min-height: 320px;
  }
}


/* Add crops modal */


.simple-form.profile .MuiFormControl-root,
.simple-form.add-crop .MuiFormControl-root {
  width: 256px;
}

@media (min-width: 960px) {
  .simple-form.profile,
  .simple-form.add-crop {
    min-height: 350px;
  }
  .simple-form.single {
    min-height: 120px;
  }
}


@media (min-width: 600px) {
  /* Crops tab */
  .simple-form.farmer .stepper-content {
    width: 100% !important;
  }
}

@media (max-width: 599.98px) {
  /* Crops tab */
  main [class*='page'] .farmer .MuiPaper-root.MuiExpansionPanel-root:first-of-type {
    margin: 0px;
  }
  .simple-form.farmer .address:first-of-type .MuiFormControl-root {
    margin-bottom: 32px !important;
  }
  .simple-form.farmer .farmer-crops > div > .MuiToolbar-root {
    display: none !important;
  }
  .simple-form.farmer .stepper-content>.MuiBox-root:nth-child(2)>.farmer-crops {
    text-align: center;
  }
  .simple-form .farmer-crops .MuiToolbar-root .MuiSvgIcon-root {
    display: block;
  }
  .simple-form.farmer .crops-title {
    width: auto;
    min-width: 32%;
    padding-left: 8px;
    padding-right: 8px;
  }
  .simple-form.farmer .crops-card .crops-details>span:first-of-type {
    padding-left: 0px;
  }
  .simple-form.farmer .crops-card .crops-details .MuiChip-root {
    margin-bottom: 6px;
  }
  .simple-form.farmer .crops-card .crops-details .MuiChip-root .MuiChip-deleteIcon {
    height: 20px;
    width: 18px;
    margin-bottom: -3.5px;
  }
  .simple-form.farmer .AkRegisterForm-body .add-crop,
  .simple-form.farmer .AkRegisterForm-body .listed-crops {
    width: 96.65%;
    padding: 0.5rem;
    margin: 10px auto;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  .simple-form.farmer .AkRegisterForm-body .listed-crops {
    margin-bottom: 20px;
  }
  .simple-form.farmer .form--subsection > div > .MuiToolbar-root {
    display: none !important;
  }
  .simple-form.farmer .listed-crops .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 0;
  }
  .simple-form.farmer .crops-list {
    padding-right: 0;
  }
  .simple-form.farmer .crops-title .MuiCardHeader-content > span:nth-child(2) {
    text-align: left;
  }
  .simple-form.farmer .AkRegisterForm-body .add-crop .MuiButton-root {
    display: block;
    margin: 0 auto !important;
  }
  .simple-form.profile > .MuiCardContent-root:first-child,
  .simple-form.add-crop > .MuiCardContent-root:first-child {
    padding: 16px !important;
  }
}

@media (max-width: 425.98px) {

  .simple-form.farmer .AkRegisterForm-body .add-crop,
  .simple-form.farmer .AkRegisterForm-body .listed-crops {
    width: 95%;
  }

  .simple-form.farmer .form--subsection .MuiTablePagination-toolbar {
    float: left;
  }

  .simple-form.farmer .form--subsection .MuiTablePagination-actions {
    margin-left: 120px;
  }
}

@media (max-width: 375.98px) {
  .simple-form.farmer .AkRegisterForm-body .add-crop,
  .simple-form.farmer .AkRegisterForm-body .listed-crops {
    width: 94%;
  }

  .simple-form.farmer .form--subsection .MuiTablePagination-actions {
    margin-left: 86px;
  }

  .simple-form.farmer .crops-title {
    min-width: 25%;
    text-align: left;
  }
}

@media (max-width: 320.98px) {
  .simple-form.farmer .AkRegisterForm-body .add-crop,
  .simple-form.farmer .AkRegisterForm-body .listed-crops {
    width: 93.25%;
  }

  .simple-form.farmer .form--subsection .MuiTablePagination-actions {
    margin-left: 35px;
  }

  .simple-form.add-crop .MuiFormControl-root {
    width: 216px;
  }
}

/* Reports
   ========================================================================== */

.report-section {
  margin: 1em;
  text-align: center;
}

.report-card {
  width: 256px;
  margin: 0.5em;
  cursor: pointer;
  min-width: 256px;
  min-height: 180px;
  vertical-align: top;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65) !important;
}

.link {
  cursor: pointer;
}

.report-card .MuiCardHeader-root {
  padding-bottom: 6px;
  align-items: flex-start;
  justify-content: space-between;
}

.report-card .MuiCardHeader-title > span {
  font-size: 0.95rem;
  font-family: inherit;
  text-transform: uppercase;
}

.report-card .MuiCardHeader-subheader,
.report-card .MuiCardHeader-content > .MuiTypography-root {
  text-align: right;
}

.report-card .MuiAvatar-root {
  width: 2.5em;
  height: 2.5em;
  background-color: #df1f26;
}

.report-card .MuiCardContent-root {
  display: flex;
  margin-top: -30px;
  flex-direction: column;
}

.report-card.farmer .MuiCardContent-root,
.report-card.groups .MuiCardContent-root {
  margin-top: -12px;
}

.report-card .MuiCardContent-root > .MuiTypography-body2 {
  font-size: 1.95rem;
}

.report-card.transactions .MuiCardContent-root > .MuiTypography-body2:last-of-type {
  font-size: 1.7125rem;
}

.report-card.farmer .MuiCardContent-root > .MuiTypography-body2,
.report-card.groups .MuiCardContent-root > .MuiTypography-body2 {
  font-size: 2.25rem;
}

.report-card .MuiCardContent-root > div {
  display: flex;
  margin-top: -16px;
  justify-content: space-evenly;
}

.report-card .MuiCardContent-root > div h3 {
  margin-bottom: 0px;
}

.report-card .MuiCardContent-root > div .MuiTypography-root {
  font-size: 1.5rem;
}

/* Wallet
   ========================================================================== */

.list-page.wallet>.MuiToolbar-root {
  display: none;
}

.list-page.wallet .MuiTablePagination-root>.MuiToolbar-root {
  justify-content: end;
}

.wallet-transactions {
  min-height: 525px;
}

.wallet-transactions>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root {
  padding: 8px 24px 8px 16px;
}

.wallet-transactions>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
  width: 30%;
}

.account-transactions>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
  width: 19%;
}

.account-transactions>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3) {
  width: 18%;
}

.account-transactions>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(4) {
  width: 25%;
}

.simple-form.deposit>.MuiCardContent-root {
  padding-top: 30px;
}

.MuiPaper-root .simple-form.deposit>.MuiCardContent-root>.ra-input {
  display: flex !important;
  justify-content: center;
}

.simple-form.deposit .MuiToolbar-root {
  margin-top: 45px;
  justify-content: center !important;
}


/* Stock
   ========================================================================== */


@media (min-width: 600px) {
  
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2) {
    width: 6%; /* Added on */
  }
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(3),  
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(4),
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(9) {
    width: 11%; /* Paid on, Farmer name & Added by */
  }   
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(6) {
    width: 4%; /* Quantity */
  }  
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(7) {
    width: 6%; /* Unit price */
  } 
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(5),   
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(8) {
    width: 7%; /*  Product name & Total price */
  }   
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root,
  .reg-pos>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root {
    vertical-align: top;
  }   
  .reg-pos>.MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:last-child,
  .reg-pos>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:last-child {
    padding-left: 0px;
  }

}

.simple-form.stock-product .stock .select-location {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  margin-top: 60px;
  width: 280px;
  max-width: 256px;
  max-height: 50vh !important;
  overflow-y: auto;
}


/* add "stock product" modal */


.simple-form.stock-product >.MuiCardContent-root .ra-input:nth-child(5),
.simple-form.stock-product .future-date .MuiInputBase-root {
  width: 256px;
}

.simple-form.stock-product .MuiFormControl-root.product .MuiInputBase-root,
.simple-form.stock-product .stock .MuiFormControl-root .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.stock.details .MuiFormLabel-root {
  color: #3f51b5 !important;
}

.stock.details label,
.MuiFormControl-root.product label {
  padding-left: 12px;
}

.stock.details label + .MuiInput-formControl,
.MuiFormControl-root.product label + .MuiInput-formControl {
  margin-top: 8px;
}

.stock label.Mui-focused,
.MuiFormControl-root.product label.Mui-focused,
.MuiFormControl-root.product label.MuiFormLabel-filled {
  margin-top: 14px;
}

.stock label.Mui-disabled {
  margin-top: 20.5px;
}

.stock label.Mui-focused,
.stock label.Mui-disabled {
  padding-left: 15px;
}

.stock .MuiInputBase-root,
.MuiFormControl-root.product .MuiInputBase-root {
  margin-bottom: 25px;
}

.stock:not(.details) .MuiFormControl-root:last-child .MuiInputBase-root {
  margin-bottom: 35px;
}

.stock.details .MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiFormControl-root.product .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.simple-form.stock-product .stock.details .MuiFormControl-root .MuiInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form.stock-product .MuiToolbar-root {
  margin-top: 0px;
}


/* Other
   ========================================================================== */

.AkbTextInput .MuiFormHelperText-root.Mui-error {
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: -22px;
}

.AkbMenuItemLink {
  min-width: 40px !important;
}

.bg-grey--light {
  background-color: #f9f9f9;
}

.block {
  display: block !important;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
}

.inline-flex.start {
  align-items: start;
}

.inline-flex.end {
  align-items: end;
}

.align-items-center {
  align-items: center;
}

.spaced {
  justify-content: space-between;
}

.center {
  text-align: center !important;
}

.center-justify {
  justify-content: center;
}

.right {
  text-align: right !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-14 {
  margin-top: 28px !important;
}

@media (min-width: 960px) {
  .mt-120-negative {
    margin-top: -120px;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-4 {
  margin-left: 8px !important;
}

.ml-9 {
  margin-left: 18px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-15-negative {
  margin-right: -15px !important;
}

.h-450 {
  height: 450px;
}

.w-256 {
  width: 256px;
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.counter {
  margin-bottom: 0px;
  font-size: 12.5px;
  text-align: end;
}

.counter .past {
  color: #f44336;
}

.no-underline {
  text-decoration: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


